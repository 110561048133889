<template>
    <header id="Header">
        <div v-if="isMobile" class="hamburger-menu">
            <button @click="toggleMenu">
                <Icon name="hamburger-menu" />
            </button>
        </div>
        <div class="logocontainer" @click.prevent="handleHome">
            <Logo />
        </div>
    </header>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import trans from "helpers/_translation";
import Icon from "components/icon";

import { isMobile } from "helpers/_device";

// Components
import Logo from "elements/logo";

export default defineComponent({
    name: "headerElement",
    components: {
        Logo,
        Icon
    },
    setup(props, { emit }) {
        const router = useRouter();

        const toggleMenu = () => {
            // Emit een event naar de bovenliggende component
            emit("toggleMenu");
        };

        const handleHome = () => {
            router.push("/");
        };

        return {
            trans,
            isMobile,
            toggleMenu,
            handleHome
        };
    },
});
</script>
