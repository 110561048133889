import { createApp } from 'vue';

import main from "main.vue";
import router from "config/router";

import moment from "moment";
import VueClickAway from "vue3-click-away";
import PrimeVueConfig from "primevue/config";

// Set our app
const app = createApp(main);
app.use(router)
	.use(VueClickAway)
	.use(PrimeVueConfig, {
		locale: {
			startsWith: 'Starts with',
			contains: 'Contains',
			notContains: 'Not contains',
			endsWith: 'Ends with',
			equals: 'Equals',
			notEquals: 'Not equals',
			noFilter: 'No Filter',
			lt: 'Less than',
			lte: 'Less than or equal to',
			gt: 'Greater than',
			gte: 'Greater than or equal to',
			dateIs: 'Date is',
			dateIsNot: 'Date is not',
			dateBefore: 'Date is before',
			dateAfter: 'Date is after',
			clear: 'Clear',
			apply: 'Apply',
			matchAll: 'Match All',
			matchAny: 'Match Any',
			addRule: 'Add Rule',
			removeRule: 'Remove Rule',
			accept: 'Yes',
			reject: 'No',
			choose: 'Choose',
			upload: 'Upload',
			cancel: 'Cancel',
			dayNames: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"],
			dayNamesShort: ["Zo","Ma","Di","Wo","Do","Vr","Za"],
			dayNamesMin: ["Zo","Ma","Di","Wo","Do","Vr","Za"],
			monthNames: ["januari","februari","maart","april","mei","juni","juli","augustus","september","oktober","november","december"],
			monthNamesShort: ["januari","februari","maart","april","mei","juni","juli","augustus","september","oktober","november","december"],
			today: 'Today',
			weekHeader: 'Wk',
			firstDayOfWeek: 0,
			dateFormat: 'mm/dd/yy',
			weak: 'Weak',
			medium: 'Medium',
			strong: 'Strong',
			passwordPrompt: 'Enter a password',
			emptyFilterMessage: 'No results found',
			emptyMessage: 'No available options'
		}
	});

app.config.globalProperties.$filters = {
	formatDate(value) {
		// console.log("value",value);
		if (value) {
			return moment(String(value)).locale('nl').format('DD MMMM YYYY HH:mm') + " uur";
		}
	},
	formatPostDate(value) {
		// console.log("value",value);
		if (value) {
			const current = moment(String(value)).locale('nl');
			if (current.isSame(new Date(), "day")) {
				return "Vandaag " + current.format('HH:mm');
			} else if (current.isSame(moment().subtract(1, 'days').startOf('day'), 'd')) {
				return "Gisteren " + current.format('HH:mm');
			} else {
				return current.format('DD MMMM YYYY HH:mm');
			}
		}
	}
};

// Mount it
app.mount('#app');