<template>
    <div class="App" :class="{
        [componentData.name] : true,
        [getSetting('layout', 'mode')] : true
        }">
        <div class="left ">
            <headerElement @toggleMenu="toggleOpenMenu" />
            <navigationElement :class="{ visible: isLeftVisible }" />
        </div>
        <div class="right">
            <div class="top">
                <div class="left">

                </div>
                <div class="right">
                </div>
            </div>
            <div class="middle">
                <router-view />
            </div>
            <!--            <footerElement />-->
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";

import headerElement from "layout/header";
import navigationElement from "layout/navigation";
import footerElement from "layout/footer";
import { getSetting } from 'store/settings';

export default defineComponent({
    name: "Layout",
    computed: {},
    components: {
        headerElement,
        navigationElement,
        footerElement
    },
    setup() {
        // Our defaults
        const route = useRoute();
        const isLeftVisible = ref(false);

        // Define our parameters
        const componentData = reactive({
            name: route.name
        });

        const toggleOpenMenu = () => {
            isLeftVisible.value = !isLeftVisible.value;
        };

        const handleDocumentClick = (event) => {
            const leftElement = document.querySelector('.left');
            if (leftElement && !leftElement.contains(event.target)) {
                isLeftVisible.value = false;
            }
        };

        watch(
            () => route.name,
            (name) => {
                componentData.name = name;
            }
        );

        onMounted(() => {
            document.addEventListener('click', handleDocumentClick);
        });

        return {
            componentData,
            isLeftVisible,
            toggleOpenMenu,
            getSetting
        };
    },
});
</script>
