import {createRouter, createWebHistory} from "vue-router";

import { checkLogin } from "services/JwtService";

import layout from "layout/layout.vue";
import homepageLayout from "layout/homepage-layout.vue";

// Defaults
import error404 from "view/error/404";
import error500 from "view/error/500";

// Dashboard related templates
import dashboardLogin from "view/dashboard/login";
import dashboardHome from "view/dashboard/home";
import dashboardView from "view/dashboard/view";

const routes = [
	{
		path: '/',
		component: homepageLayout,  // Use the unique layout for the homepage
		children: [
			{
				path: '',
				name: "Dashboard home",
				component: dashboardHome,
				meta: {
					module: "Dashboard",
					action: "view"
				}
			}
		]
	},
	{
		path: "/widget",
		component: layout,
		alias: [
			"/widget/:payload"
		],
		children: [
			{
				path: "",
				name: "Dashboard widget",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "widget"
				}
			}
		]
	},
	{
		path: "/404",
		component: homepageLayout,
		children: [
			{
				path: "",
				name: "404",
				component: error404,
				meta: {
					module: "Document",
					action: "view"
				}
			}
		]
	},
	{
		path: "/500",
		component: homepageLayout,
		children: [
			{
				path: "",
				name: "500",
				component: error500,
				meta: {
					module: "Document",
					action: "view"
				}
			}
		]
	},
	{
		path: "/login",
		name: "Login",
		component: dashboardLogin,
		meta: {
			module: "Dashboard",
			action: "login"
		}
	},
	{
		// the 404 route, when none of the above matches
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

const router = createRouter({
	saveScrollPosition: false,
	history: createWebHistory(),
	routes
});
function truncate(text, length = 25) {
	if (text.length <= length) {
		return text;
	}
	return text.substr(0, length) + '\u2026';
}

router.beforeEach((to, from, next) => {
	// Check for verification
	console.log(`Route to: "${truncate(to.path)}" from: ${truncate(from.path)}`);
	if (to.path !== "/login" && to.path !== "/forget" && from.path !== '/login' && !checkLogin()) {
		next("/login");
	}
	if (to.path === "/login") {
		next();
	}

	// Validate visibility
	next();

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});
export default router;