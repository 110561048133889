// Services
import { refreshToken , getBearer } from "services/JwtService";

// Helper functions
import { error } from "helpers/_error";
import { resolveObject, resolveData } from "helpers/_data";
import { getUrlWithFiltersAdded } from "helpers/_structure";

import filtersConfig from "config/filters";

// Resolve filters based on "default_filters.js" configuration file
export function resolveFilters(module_access, append_default = true) {
    const result = JSON.parse(JSON.stringify(filtersConfig[module_access] || []));
    if (append_default) {
        result.push(...JSON.parse(JSON.stringify(filtersConfig.default)));
    }
    return result;
}

// Handle our fetch, can contain any method
export function fetchData(url, method = "GET", body) {
    return new Promise((resolve, reject) => {
        if (body) {
            // Format it correctly
            body = JSON.stringify(resolveObject(body));
        }
        fetch(url, {
            method: method,
            headers: getBearer(),
            body
        })
        .then((result) => {
            const status = result.status;
            if (status === 401) {
                // Unauthorized
                refreshToken(1).then(() => {
                    // Retry
                    fetchData(url, method, body).then(resolve).catch(reject)
                }, (error) => {
                    console.error(error);
                    reject(error);
                    window.location = "/login";
                });
            } else if (status > 204) {
                error(url, `async fetch: ${status}`);
                result.json().then(json => {
                    json.status = status;
                    reject(json);
                });
            } else if ([204].includes(status)) {
                resolve(status);
            } else {
                result.json().then(json => {
                    resolve(json, status);
                });
            }
        })
        .catch(reject);
    });
}

// Async variant
export async function fetchDataAsync(url, method, body) {
    return fetchData(url, method, body);
}

// Wrapper function for GET data
export function getData(originalUrl, body) {
    return new Promise((resolve, reject) => {

        // Pass body as GET parameters since GET won't take a body
        let url = originalUrl;
        if (body instanceof Array) {
            url = getUrlWithFiltersAdded(body, url);
            body = null;
        }

        fetchData(url, "GET", body)
            .then((json, result) => {
                // Do something extra, for example handle result status.
                resolve(json, result);
            })
            .catch((message) => {
                // Handle error
                reject(message);
            });
    });
}
export async function getDataAsync(url, body) {
    return getData(url, body);
}
export async function getDataWithoutLimit(url, filters, target_object, target_key) {
    return new Promise(resolve => {
        return getDataAsync(url, filters).then(data => {
            target_object[target_key] = [...target_object[target_key], ...resolveData(data)];
            if (data._links.next) {
                // filters already been passed in the URL no need for extra filters
                return getDataWithoutLimit(data._links.next.href, [], target_object, target_key).then(resolve)
            } else {
                return resolve();
            }
        });
    });
};

// Wrapper function for POST data
export function postData(url, body) {
    // if (typeof body == "object") {
    //     body = JSON.stringify();
    // }
    return new Promise((resolve, reject) => {
        fetchData(url, "POST", body)
            .then(
                (json, result) => {
                    // Do something extra, for example handle result status.
                    resolve(json, result);
                },
                reject
            );
    });
}
export async function postDataAsync(url, body) {
    return postData(url, body);
}

// Wrapper function for PUT data
export function putData(url, body) {
    return new Promise((resolve, reject) => {
        fetchData(url, "PUT", body)
            .then(
                (json, result) => {
                    // Do something extra, for example handle result status.
                    resolve(json, result);
                },
                reject
            );
    });
}
export async function putDataAsync(url, body) {
    return putData(url, body);
}

// Wrapper function for PATCH data
export function patchData(url, body) {
    return new Promise((resolve, reject) => {
        fetchData(url, "PATCH", body)
            .then(
                (json, result) => {
                    // Do something extra, for example handle result status.
                    resolve(json, result);
                },
                reject
            );
    });
}
export async function patchDataAsync(url, body) {
    return patchData(url, body);
}

// Wrapper function for DELETE data
export function deleteData(url, body) {
    return new Promise((resolve, reject) => {
        fetchData(url, "DELETE", body)
            .then(
                (json, result) => {
                    console.log("result", result);
                    // Do something extra, for example handle result status.
                    resolve(json, result);
                },
                reject
            );
    });
}
export async function deleteDataAsync(url, body) {
    return deleteData(url, body);
}
