import { ref, watch } from "vue";

import { setTranslationObject } from "helpers/_translation";

export const languageOptions = {
	"nl_NL": {
		"label": "Nederlands",
		"data": {
			"Inloggen": "Inloggen",
			"E-mailadres": "E-mailadres",
			"Wachtwoord": "Wachtwoord",
			"Nederlands": "Nederlands",
			"Engels": "Engels",
			"Wachtwoord vergeten" : "Wachtwoord vergeten",
			"Wachtwoord aanvragen" : "Wachtwoord aanvragen"
		}
	},
	"en_GB": {
		"label": "Engels",
		"data": {
			"Inloggen": "Login",
			"E-mailadres": "Email address",
			"Wachtwoord": "Password",
			"Nederlands": "Dutch",
			"Engels": "English",
			"Wachtwoord vergeten" : "Forgot password",
			"Wachtwoord aanvragen" : "Request password",
			"Home" : "Home",
			"Afspraken" : "Appointments",
			"Facturen" : "Invoices",
			"Tickets" : "Tickets",
			"Diensten" : "Services",
			"Content" : "Content",
			"Overzicht" : "Overview",
			"Instellingen" : "Settings",
			"Donkere modus" : "Dark mode",
			"Lichte modus" : "Light mode",
			"Uitloggen" : "Log out",
			"titel": "title",
			"Organisatie": "Organisation",
			"Deadline bundel akkoord": "Deadline bundle agreement",
			"Deadline publicatie": "Deadline publication",
			"Briefing": "Briefing",
			"Verantwoordelijke content": "Responsible for content",
			"Verantwoordelijke design": "Responsible for design",
			"Opslaan": "Save",
			"Te controleren": "To be checked",
			"Ingepland": "Scheduled",
			"Historie": "History",
			"Terug naar vorige pagina": "Back to previous page",

			"Content controleren": "Check content",
			"Content historie": "Content history",
			"Ingeplande content": "Scheduled content",
			"Titel": "Title",
			"Klant": "Customer",
			"Deadline productie": "Production deadline",
			"Opmerkingen": "Comments",
			"Versturen": "Send",
			"Goedgekeurd": "Approved",
			"Correcties door laten voeren": "Have corrections carried out",
			"Plaats opmerking": "Add comment",
			"Een nieuw bericht typen ..": "Type a new message ...",

			"Contact opnemen": "Get in touch",
			"Hoe kunnen we je helpen?" : "How can we help you?",
			"Mijn profiel" : "My profile",
			"Gegevens" : "Datails",
			"Voornaam" : "First name",
			"Achternaam" : "Last name",
		}
	}
};

const currentLanguage = ref(window.localStorage.getItem("country_code") ?? Object.keys(languageOptions)?.[0]);

export const setLanguage = (country_code) => {
	currentLanguage.value = country_code;
	window.localStorage.setItem("country_code", country_code);
};
export const getLanguage = () => {
	return currentLanguage.value;
};

watch(
	() => currentLanguage.value,
	(key) => {
		if (!key) return;
		setTranslationObject(languageOptions[key]?.data ?? Object.values(languageOptions)?.[0]?.data);
	},
	{
		immediate: true
	}
);
