const regexPhone = /^[0\+]{1}[0-9]{8,15}$/i;
const regexPhoneWithLandCode = /^(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{8,9}$|[0-9\-\s]{9,12}$)$/i;
const regexMail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;

export const validatePattern = function (string, pattern = "") {
    if (!pattern) return true;
    const regexPattern = new RegExp(`^${pattern}$`);
    return regexPattern.test(string);
}

const formatPhoneNumber = (number) => { 
	return number.replace(/[\s-]/g, '').replace("(0)","").replace("(", "").replace(")", "");
}

/**
 * Validate the given phone number. It must be 10 to 16 digits
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validatePhoneNumber = function (number, revertLastChar) {
    return regexPhone.test(formatPhoneNumber(number));
}

/**
 * Validate the given phone number. It must be 10 to 16 digits
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validatePhoneNumberWithLandCode = function (number, revertLastChar) {
    return regexPhoneWithLandCode.test(formatPhoneNumber(number));
}

/**
 * Validate the given number with the "11 proef"
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validate11Proof = function (number, revertLastChar) {
    var sum = 0;
    var chars = number.toString().split("");
    var length = chars.length;
    // First check if the given value contains 9 numbers
    if (length !== 9) {
        return false;
    }

    // Loop thru the chars
    for (var index in chars) {
        // Get the right weight
        var weight = length - index;
        if (weight === 1 && revertLastChar === true) {
            weight = weight * -1;
        }

        // Added the new value to the sum
        sum += chars[index] * weight;
    }

    // Returns if the sum is divisible by 11
    return sum % 11 === 0;
}

/**
 * Validate VAT number
 *
 * @param {int} number
 * @param {Boolean} revertLastChar
 *
 * @returns {Boolean}
 */
export const validateVAT = function (number, revertLastChar) {
    // Verwijder spaties uit het BTW-nummer
    number = number.replace(/\s/g, '');

    // Het BTW-nummer moet 14 tekens lang zijn
    if (number.length !== 14) {
        return false;
    }

    // Het eerste teken moet 'NL' zijn
    if (number.substr(0, 2).toUpperCase() !== 'NL') {
        return false;
    }

    // de twaalfde character moet de letter 'B' zijn
    if (number.substr(11, 1).toUpperCase() !== 'B') {
        return false;
    }

    if (!/^NL[0-9]{9}B[0-9]{2}$/.test(number)) {
        return false;
    }

    return true;
}

export const validateVATInternational = function (number, countryCode) {
    switch (countryCode) {
        case 'BE': // Belgium
            return {
                "valid": /^BE0[0-9]{9}$/.test(number),
                "error": `Het nummer moet in het volgende formaat worden ingevoerd: BE0999999999 - 1 blok van 12 karakters. Voorbeeld: BE0000000000`
            };
        case 'DE': // Germany
            return {
                "valid": /^DE[0-9]{9}$/.test(number),
                "error": `Het nummer moet in het volgende formaat worden ingevoerd: DE999999999 - 1 blok van 11 karakters. Voorbeeld: DE000000000`
            };
        case 'ES': // Spain
            return {
                "valid": /^ES[0-9A-Z][0-9]{7}[0-9A-Z]$/.test(number),
                "error": `Het nummer moet in het volgende formaat worden ingevoerd: ESX9999999X - 1 blok van 11 karakters. Voorbeeld: ESA0000000B`,
            };
        case 'FR': // France
            return {
                "valid": /^FR[0-9A-Z]{2}[0-9]{9}$/.test(number),
                "error": `Het nummer moet in het volgende formaat worden ingevoerd: FRXX999999999 - 1 blok van 13 karakters. Voorbeeld: FRAA000000000`
            };
        case 'GB': // United Kingdom
            return {
                "valid": /^GB([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/.test(number),
                "error": `Het nummer moet in één van de volgende formaten worden ingevoerd: GBXX999 | GB999999999 | GB999999999999 - 1 blok van 7, 11 of 14 karakters. Voorbeeld: GB000000000000`,
            };
        case 'IT': // Italy
            return {
                "valid": /^IT[0-9]{11}$/.test(number),
                "error": `Het nummer moet in één van de volgende formaten worden ingevoerd: IT99999999999 - 1 blok van 13 karakters. Voorbeeld: IT00000000000`,
            };
        case 'LU': // Luxembourg
            return {
                "valid": /^LU[0-9]{8}$/.test(number),
                "error": `Het nummer moet in het volgende formaat worden ingevoerd: LU99999999 - 1 blok van 10 karakters. Voorbeeld: LU00000000`
            };
        case 'NL': // Netherlands
            return {
                "valid": validateVAT(number),
                "error": `Het nummer moet in het volgende formaat worden ingevoerd: NL999999999B99 - 1 blok van 14 karakters. De 12e positie is altijd 'B'. Voorbeeld: NL000000000B00`
            };
        default:
            return {
                "valid": false,
                "error": `Het formaat van dit BTW-nummer wordt niet door ons herkend`
            };
    }
}

/**
 * Validate e-mail address
 *
 * @param {int} number
 * @param {string} revertLastChar
 *
 * @returns {string}
 */
export const validateEmail = function(value){
    return regexMail.test(value);
}

// Calculate password strength
export const calculatePasswordStrength = function(value) {
    let score = 0;
    const regexStrings = [
        /[a-z]+/g,
        /[A-Z]+/g,
        /[0-9]+/g,
        /\W+/g,
    ];

    regexStrings.forEach(regexString => {
        const regex = new RegExp(regexString);
        if (regex.test(value)) {
            score++;
        }
    })

    if (value.length >= 8) {
        score++;
    }

    return score;
}