<template>
    <div class="maincontainer">
        <div class="maincolumn">
            <div class="Block Widget Overview">
                <template v-for="site_config in config_values('site.widgets.enable')">
                <h2 class="h2">{{ entity("site", site_config.site_id)?.data.title }}</h2>
                <div class="Quicklinks">
                    <template v-for="module in site_config.value ?? []">
                        <button class="Quicklink useHand" type="button" @click.prevent="handleClick('/widget', { module: module, site_id: site_config.site_id })">
                            <icon :name="module_icon(module)" />
                            <div class="Heading">
                                <div class="title">{{ module_title(module) }}</div>
                            </div>
                        </button>
                    </template>
                </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, inject, watch, computed} from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";
import {env} from "config";

import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "dashboardView",
    components: {
        Icon
    },
    setup() {

        const entity = inject("entity");
        const config_values = inject("config_values");

        const module_config = inject("module_config");
        const module_title = inject("module_title");
        const module_icon = inject("module_icon");

        const handleClick = inject("handleClick");

        return {
            env,

            trans,

            handleClick,

            entity,
            config_values,

            module_config,
            module_title,
            module_icon
        }
    }
});
</script>
