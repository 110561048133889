// Our Vue related imports
import {computed, ref, watch, reactive} from 'vue';
import {createStore} from 'vuex';

import {getData, getDataAsync, getDataWithoutLimit, resolveFilters} from "helpers/_api";
import {getQuery} from "helpers/_structure";

// Config for API urls used in this vue template
import {apiHostname} from "config";

const CACHE_FORCE_VERSION = computed(() => window.CACHE_FORCE_VERSION ?? "1.0.0"); // Used as killswitch, forcing the cache to be cleared when mayor changes are made

export const CACHE_EXPIRATION_TIMES = {
	"1hour": 1 * 60 * 60 * 1000,
	"24hours": 24 * 60 * 60 * 1000,
	"5days": 5 * 24 * 60 * 60 * 1000
}

const storeState = (key, data) => {
	localStorage.setItem(`${key}_state`, JSON.stringify(data));
}

const cleanupEntity = (entity) => {
	const tmpEntity = {...entity};
	delete tmpEntity._links;
	const _meta = {timestamp: entity._meta.timestamp};
	tmpEntity._meta = _meta;
	return tmpEntity;
}
const isValidCache = (entity, expiration_time = CACHE_EXPIRATION_TIMES["24hours"]) => {
	if (entity?.fetching) {
		return true;
	}
	if (entity?._meta) {
		const now = Date.now();
		const timestamp = (typeof entity._meta?.timestamp === "number") ? entity._meta?.timestamp : Date.parse(entity._meta?.timestamp);
		if (now - timestamp <= expiration_time) {
			return true;
		}
	}
	return false;
}

const restoredStates = {
	entity: {},
	svg: {
		icons: {}
	}
}
Object.keys(restoredStates).forEach(key => {
	const storage = localStorage.getItem(`${key}_state`);
	if (storage) {
		try {
			const storageData = JSON.parse(storage || "[]");
			restoredStates[key] = storageData?.version === CACHE_FORCE_VERSION.value ? storageData : restoredStates[key];
		} catch (e) {
			// Do nothing
		}
		Object.keys(restoredStates[key]).forEach((type) => {
			const tmpItems = Object.keys(restoredStates[key][type]);
			tmpItems && tmpItems.forEach(item_id => {
				if (restoredStates[key][type]?.[item_id]?.fetching) {
					delete restoredStates[key][type]?.[item_id];
				}
			})
		});
	}
});

const store = createStore({
	modules: {
		entities: {
			namespaced: true,
			state() {
				return {
					...JSON.parse(JSON.stringify(restoredStates["entity"]))
				}
			},
			mutations: {
				set(state, {
					module_access,
					item_id,
					entity
				}) {
					if (!(module_access in state)) {
						state[module_access] = {};
					}
					state[module_access][item_id] = entity;
					state.version = CACHE_FORCE_VERSION.value;
				},
				all(state, {
					module_access,
					entities,
					uuid
				}) {
					if (!("all" in state)) {
						state["all"] = {};
					}
					if (!(uuid in state)) {
						state["all"][uuid] = [];
					}
					state["all"][uuid] = entities;
					state.version = CACHE_FORCE_VERSION.value;
				}
			},
			getters: {
				all: (state) => () => {
					return state["all"];
				},
				get: (state) => (module_access, item_id) => {
					return computed(() => {
						return state[module_access]?.[item_id];
					});
				}
			},
			actions: {

				get({commit, state}, {
					module_access,
					item_id,
					force_refresh = false,
					cache_time = CACHE_EXPIRATION_TIMES["1hour"]
				}) {
					// Check if entity already exists and define variable
					const cached_entity = state[module_access]?.[item_id];
					if (state?.[module_access]
						&& (item_id in state?.[module_access])
						&& !force_refresh
						&& isValidCache(cached_entity, cache_time)
					) {
						// Check if entity should be refreshed or can stay cached
						return Promise.resolve(cached_entity)
					}
					// Or get our data and store the entity
					commit('set', {
						module_access,
						item_id,
						entity: {fetching: true}
					});
					return new Promise((resolve, reject) => {
						getDataAsync(`${apiHostname}/api/v1.0/${module_access}/${item_id}`).then(result => {
							const entity = cleanupEntity(result);
							commit('set', {
								module_access,
								item_id,
								entity
							});
							storeState('entity', state);
							resolve(state[module_access][item_id]);
						});
					});
				},

				all({commit, state, getters}, {
					module_access,
					uuid,
					defined_filters = [],
					force_refresh = false,
					cache_time = CACHE_EXPIRATION_TIMES["1hour"]
				}) {
					if (!uuid) (uuid = module_access);
					// Check if entity already exists and define variable
					const cached_entities = getters.all()?.[uuid];
					if (!force_refresh && isValidCache(cached_entities, cache_time)) {
						// Check if entity should be refreshed or can stay cached
						return Promise.resolve(cached_entities)
					}
					// Or get our data and store the entity
					commit('all', {
						module_access,
						entities: {fetching: true},
						uuid
					});
					return new Promise((resolve, reject) => {
						const now = Date.now();
						const tmpEntities = reactive({
							data: [],
							fetching: true,
							_meta: {
								timestamp: now
							}
						});
						watch(
							() => tmpEntities.data.length,
							(newValue, oldValue) => {

								tmpEntities.data.slice(oldValue, newValue).forEach(entity => {
									const tmpEntity = {
										data: entity,
										_meta: {
											timestamp: now
										}
									}
									commit('set', {
										module_access: module_access,
										item_id: tmpEntity.data.id,
										entity: tmpEntity
									});
								});

								const entities = JSON.parse(JSON.stringify(tmpEntities));
								commit('all', {
									module_access,
									entities,
									uuid
								});
							}
						);

						const handleResult = () => {
							const entities = JSON.parse(JSON.stringify(tmpEntities));
							storeState('entity', state);
							resolve(entities);
						}

						const filters = resolveFilters(module_access, true);
						filters.push(
							[
								"FieldFilter",
								{
									"c": `${module_access}_id`,
									"v": "null",
									"o": "="
								}
							],
							...defined_filters
						);
						let url = `${apiHostname}/api/v1.0/${module_access}`
						if (module_access === "productgroup") {
							url = `${apiHostname}/api/v1.0/${module_access}?limit=100`
						}
						getDataWithoutLimit(
							url,
							filters,
							tmpEntities,
							"data"
						).then(() => {
							tmpEntities.fetching = false;
							const entities = JSON.parse(JSON.stringify(tmpEntities));
							commit('all', { module_access, entities, uuid });

							if (!tmpEntities.data.length || !(`${module_access}_id` in tmpEntities.data[0])) {
								// If no items or no `${module_access}_id` column, return
								return handleResult();
							}

							// Second call for subitems
							return getDataWithoutLimit(
								url,
								[
									...resolveFilters(module_access, true),
									...defined_filters,
									[
										"FieldFilter",
										{
											"c": `${module_access}_id`,
											"v": "null",
											"o": "<>"
										}
									]
								],
								tmpEntities,
								"data"
							).then(handleResult);
						});
					});
				}


			}
		},
		svgs: {
			namespaced: true,
			state() {
				return {
					...JSON.parse(JSON.stringify(restoredStates["svg"]))
				}
			},
			getters: {
				all: (state) => () => {
					return state.icons;
				},
				get: (state) => (name) => {
					if (!(name in state.icons)) {
						// Define the icon if it doesn't exist
						state.icons[name] = {};
					}
					return state.icons[name];
				}
			},
			mutations: {
				set(state, {name, svg}) {
					state.icons[name] = svg;
					state.version = CACHE_FORCE_VERSION.value;
				}
			},
			actions: {

				get({commit, state, getters}, {name, force_refresh = false}) {
					// Check if entity already exists and define variable
					const icon = getters.get(name);
					if (!force_refresh && isValidCache(icon, CACHE_EXPIRATION_TIMES["1hour"])) {
						// Check if entity should be refreshed or can stay cached
						return;
					}
					// Or get our data and store the entity
					commit('set', {name, svg: {fetching: true, data: ""}});
					// querySelectorAll (to deal with defaults)
					const path = window.Site.path ?? "";
					const target = document.querySelector(`#svg-sprite #${name}`);
					if (target) {
						const svg = {
							data: target.outerHTML,
							fetching: false,
							_meta: {
								timestamp: Date.now()
							}
						}
						commit('set', {name, svg});
						storeState("svg", state);
					} else {
						getDataAsync(`${apiHostname}/webcore/frontendtheme/svg?name=${name}&path=${path}`).then(result => {
							const svg = {
								...result,
								fetching: false
							};
							commit('set', {name, svg});
							storeState("svg", state);
						});
					}
				}


			}
		}
	}
});

export const entity = (module_access, item_id, force_refresh = false, cache_time = CACHE_EXPIRATION_TIMES["1hour"]) => {
	return computed(() => {
		const item = store.getters["entities/get"](module_access, item_id);
		if (!item || force_refresh || !isValidCache(item, cache_time)) {
			store.dispatch("entities/get", {
				module_access,
				item_id,
				force_refresh,
				cache_time
			});
		}
		return item;
	}).value;
}
export const child_ids = (module_access, item_id) => {
	module_access = module_access.toLowerCase();
	return computed(() => {
		const item = entity(module_access, item_id);
		return (item?.value?.data?.child_ids || "").split(",");
	}).value;
};
export const entities = (module_access, uuid, defined_filters = [], force_refresh = false, cache_time = CACHE_EXPIRATION_TIMES["1hour"]) => {
	defined_filters.push([
		"Field",
		{
			"c": "language",
			"v": document.body.dataset.language ?? getQuery("language") ?? "nl_NL"
		}
	]);
	store.dispatch("entities/all", {
		module_access,
		uuid,
		defined_filters,
		force_refresh,
		cache_time
	});
	return store.getters["entities/all"]();
}

export const store_entity = (module_access, data) => {
	store.commit("entities/set", {
		module_access: module_access,
		item_id: data.data.id,
		entity: data
	});
	if (!(module_access in entities(module_access))) {
		entities(module_access)[module_access] = {
			data: []
		};
	}
	entities(module_access)?.[module_access]?.data?.push(data.data);
	storeState('entity', store.state);
}


// For some reason getting a single icon isn't working when fetching async
export const svgs = (name) => {
	store.dispatch("svgs/get", {name});
	return store.getters["svgs/all"]();
}

export default store;

// Example installation:
// In the .js file: app.use(store);
// Within the component: const store = useStore()

// Example usage:
// Trigger a mutation: store.commit('increment')
// Get the data: console.log(store.state.count)

// Or use as function
// store.entities.dispatch('entities/get', {
// 	module_access: "brand",
// 	item_id: 1
// });