import { reactive, inject, provide, watch } from "vue";

// Helper functions
import { validatePattern, validateEmail, validatePhoneNumberWithLandCode, validate11Proof, validateVAT, validateVATInternational } from "helpers/_validate";

import trans from "helpers/_translation";

const defaults = {
    init: false,
    error: false,
    message:""
};

export function useFormValidation(){
    const formObject = reactive({
        submitting: false,
        validators: [],
        errors: []
    });

    const handleSubmit = (e) => {
        return new Promise((resolve, reject) => {
            // First reset our validators, since it can be changed
            formObject.validators = [];
            formObject.errors = [];
            // Trigger our submitting events
            formObject.submitting = true;
            setTimeout(() => {
                // Validate all fields registered
                formObject.validators.forEach(validator => validator());
                // If there are any errors, return
                formObject.submitting = false;
                if (formObject.errors.some(result => result.error)) {
                    return console.log(formObject.errors) && reject(formObject.errors);
                };
                // Otherwise handleSucces
                return resolve({
                    success: true
                });
            }, 100);
        });
    }

    return {
        formObject,
        handleSubmit
    };
}

export default function useFieldValidation(){

    const error = reactive({...defaults});
    const resetError = () => {
        Object.assign(error, defaults);
    };

    const defaultValidation = (fieldValue) => {
        if (!fieldValue) {
            error.error = true;
            error.message = trans(`%s is vereist.`, trans(validationObject.fieldName));
        } else if (validationObject.pattern && !validatePattern(fieldValue, validationObject.pattern)) {
            error.error = true;
            error.message = trans(`%s is niet juist ingevuld.`, trans(validationObject.fieldName));
        }
    }
    const emailValidation = (fieldValue) => {
        if (!validateEmail(fieldValue)) {
            error.error = true;
            error.message = trans(`Vul a.u.b. een geldig emailadres in.`);
        }
    };
    const optionValidation = (fieldValue) => {
        if (!fieldValue.length) {
            error.error = true;
            error.message = trans(`Een keuze voor %s is vereist.`, trans(validationObject.fieldName));
        }
    };
    const phoneValidation = (fieldValue) => {
        if (!validatePhoneNumberWithLandCode(fieldValue)) {
            error.error = true;
            error.message = trans(`%s is niet juist ingevuld.`, trans(validationObject.fieldName));
        }
    };
    const vatValidation = (fieldValue) => {
        let countryCode = "NL";
        const countryCodeEl = document.querySelector("select#field_country");
        if (countryCodeEl && countryCodeEl.value && countryCodeEl.value.length > 0) {
            countryCode = countryCodeEl.value;
        }

        const result = validateVATInternational(fieldValue, countryCode);
        if (!result.valid) {
            error.error = true;
            error.message = trans(result.error, trans(validationObject.fieldName));
        }
    };
    const ibanValidation = (fieldValue) => {
        if (!validate11Proof(fieldValue)) {
            error.error = true;
            error.message = trans(`%s is niet juist ingevuld.`, trans(validationObject.fieldName));
        }
    };



    let validatorInput = null;
    let validatorArray = [];
    const validationObject = reactive({
        // stores the function, as reference
        validator: null,
        // Variables
        type: null,
        fieldName: "Dit veld",
        // Error
        error
    });

    const validate = (e) => {
        if (e?.code && e.code === "Tab") return;
        if (!validationObject.validator) return;
        // Start with a error reset
        resetError();
        // Loop through our validators
        validatorArray.forEach(validator => {
            if (error.error) return;
            validator(validatorInput.value);
        });
        // Foreach finished, no errors found. Set error.init = true;
        if (!error.error) {
            error.init = true;
        }
    }

    const buildValidatorArray = (type) => {
        // Build our validator array
        validatorArray.push(defaultValidation);
        switch (validationObject.type) {
            case "PhoneField":
                validatorArray.push(phoneValidation);
                break;
            case "EmailField":
                validatorArray.push(emailValidation);
                break;
            case "VatField":
                validatorArray.push(vatValidation);
                break;
            case "CheckboxField":
                validatorArray.push(optionValidation);
                break;
            default:
        }
    }
    const registerValidator = (input, attributes) => {
        // Register variables
        validatorArray = [];
        validatorInput = input;
        validationObject.type = attributes.type;
        validationObject.fieldName = attributes.label;
        validationObject.pattern = attributes.pattern;
        // Set validator, if it should be added
        if (!attributes.required) return;
        validationObject.validator = validate;
        buildValidatorArray(attributes.type);
    };


    const formObject = inject("formObject");
    watch(
        () => formObject?.submitting,
        (submitting) => {
            if (!submitting || !validationObject.validator) return;
            formObject.validators.push(validationObject.validator);
            formObject.errors.push(error);
        }
    );



    return {
        error,
        registerValidator,
        validate
    };
}