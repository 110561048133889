// Helper functions
import { mergeDeep } from "helpers/_objects";

import { computed, ref } from "vue";

import "config/langs";

// Configs
import * as site from './module/site';
import * as brand from './module/brand';
import * as moduleConfig from './module/config';
import * as historylog from './module/historylog';
import * as links from './module/links';
import * as message from './module/message';
import * as navigation from './module/navigation';
import * as notification from './module/notification';
import * as product from './module/product';
import * as productexportchannel from './module/productexportchannel';
import * as productgroup from './module/productgroup';
import * as refine from './module/refine';
import * as refinegroup from './module/refinegroup';
import * as upload from './module/upload';
import * as user from './module/user';

export const apiHostname = process.env.VUE_APP_API_HOSTNAME;

// const environmentConfig = ref(process.env.NODE_ENV === 'development' ? {} : environmentJson);
// if (process.env.NODE_ENV === 'development') {
// 	// For development purpose load the config json async so that it renews the localhost environment variables
// 	fetch(`/env/${process.env.VUE_APP_ENVIRONMENT_CONFIG}.json`).then(response => {
// 		return response.json();
// 	}).then(data => {
// 		environmentConfig.value = data;
// 	});
// }

const config = computed(() => {
	return {
		// environment: environmentConfig.value,
		site,
		brand,
		config: moduleConfig,
		historylog,
		links,
		message,
		navigation,
		notification,
		product,
		productexportchannel,
		productgroup,
		refine,
		refinegroup,
		upload,
		user
	}
});

// Function which uses default to fallback
// Can be used for example like this 'getConfigValue("product", "cache.expire_duration")'
import * as defaults from './module/_defaults';
export const getConfigValue = (module, keys) => {
	return computed(() => {
		// Merge default config with module config
		const mergedConfig = mergeDeep(defaults, (module in config.value) ? config.value[module] : {});

		// Now define our value by walking through our tree
		let value = mergedConfig;
		var namespaces = keys.split(".");
		for (var i = 0; i < namespaces.length; i++) {
			value = value?.[namespaces[i]];
		}
		return value;
	}).value;
};
// Wrapper function for client specified environment variables defined in for example /public/env/ibizz.json
// Can be used for example like this 'env("cache.expire_duration")'
export const env = (keys) => {
	// console.log("keys",keys);
	return getConfigValue("environment", keys);
};

export default config;