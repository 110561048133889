// Example of conditional fields
//
// Add the following to 'data' of a field:
// "conditions": [
// 	{
// 		"logical_operator": "AND",
// 		"logical_values": [
// 			{
// 				"projecttype_id": {
// 					"operator": "=",
// 					"value": ["8"]
// 				}
// 			}
// 		]
// 	}
// ]

export const propDefaults = {
    // Initial properties
    module_object: {type: String, required: false, default: ""},
    id: {type: (Number, String), required: true, default: ""},
    name: {type: String, required: true},
    label: {type: String, required: true, default: ""},
    tooltip_information: {type: String, required: false, default: ""},
    type: {type: String, required: false, default: "TextField"},
    placeholder: {type: String, required: false, default: ""},
	note: {type: String, required: false, default: ""},
    autocomplete: {type: Boolean, required: false, default: false},
    maxlength: {type: Number, required: false, default: ""},
    pattern: {type: String, required: false, default:""},
    required: {type: Boolean, required: false, default: false},
    readonly: {type: Boolean, required: false, default: false},
    float: {type: Boolean, required: false, default: true},

    // Data properties
    value: {type: String, required: false, default: null},
    options: {type: String, required: false, default: ""},
	options_json: {type: String, required: false, default: ""}, // Used for reference and checks
	options_object: {type: String, required: false, default: ""}, // Used for reference and checks
    // Extra data that can be passed
    // data: {type: Boolean, required: false, default: {}},

    // Extra parameters, used for external behaviour
    editing: {type: Boolean, required: false, default: true},
    translate: {type: Boolean, required: false, default: false},

    // Extra parameters for template behaviour, passed by fields.vue
    show_label: {type: Boolean, required: false, default: true},
    show_placeholder: {type: Boolean, required: false, default: false},
    show_placeholder_on_input: {type: Boolean, required: false, default: true},
    show_error_message: {type: Boolean, required: false, default: true},
    show_state: {type: Boolean, required: false, default: true},
    show_tooltip_inline: {type: Boolean, required: false, default: false}
};

export const resolveProps = (type = "label", props = {}) => {
    const keys = [];
    switch (type) {
        case 'maxlength':
            keys.push("maxlength", "data", "translate")
            break;
        case 'tooltip':
            keys.push("tooltip_information", "show_tooltip_inline", "translate")
            break;
        case 'label':
            keys.push("id", "label", "show_label", "required", "tooltip_information", "show_tooltip_inline", "translate")
            break;
        case 'placeholder':
            keys.push("placeholder", "required", "show_placeholder", "show_placeholder_on_input", "translate")
            break;
        case 'state':
            keys.push("show_error_message", "translate")
            break;
        case 'error':
            keys.push("show_error_message", "translate")
            break;
        default:
    }

    // Now build our object with props
    const result = {};
    keys.forEach(key => {
        result[key] = props[key];
    });
    return result;
}