import { reactive, watch, computed, toRefs } from "vue";
import { getData, getDataAsync } from "helpers/_api";
import { apiHostname } from "config";
import { checkLogin } from "services/JwtService.js";

import { useRoute } from "vue-router";

import {env} from "config";

const CONTACT_KEY = "app_contact";
const COMPANY_KEY = "app_company";

const portalDefaults = {
	loading: false,
	fetching: [],
	me: {
		data: {
			id: null,
			extra: {
				usergroup_ids: []
			}
		}
	},

	user: [],
	config: []
};
export const portalStore = reactive({
	...portalDefaults
});

// Our data
export const me = computed(() => portalStore.me);
export const user_id = computed(() => portalStore.me.data.id);
export const usergroup_ids = computed(() => portalStore.me.data.extra.usergroup_ids);

export const role = computed(() => {
	// const tmpValue = value(contact.value, env("variables.contact.properties.role"));
	// if (tmpValue) {
	// 	return tmpValue.split(",");
	// }
	return [];
});

const fetchMe = (renew = false) => {
	if (portalStore.loading || (me.value.data.id !== null && !renew)) return;
	if (!checkLogin()) return;
	portalStore.loading = true;
	// Set empty object so it can't be queried multiple times
	getData(`${apiHostname}/api/v1.0/me`).then((json) => {
		portalStore.me = json;
		portalStore.loading = false;
		console.log("me", portalStore.me);
	});
};
const resetMe = () => {
	portalStore.me = {...portalDefaults.me};
	portalStore.loading = false;
};



export const init = () => {
	// Keep track of the route to handle changes
	const route = useRoute();

	// Check for login state
	watch(
	    () => route.path,
	    (path) => {
	        if (!checkLogin()) {
				return resetMe();
			}
			fetchMe();
	    },
	    {
	        immediate: true
	    }
	);
};