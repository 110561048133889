<template>
    <div class="App" :class="{
        [componentData.name] : true
        }">
        <div class="center">
            <div class="middle">
        <router-view />
            </div>
        <footerElement />
            </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";

import headerElement from "layout/header";
import navigationElement from "layout/navigation";
import footerElement from "layout/footer";
import { getSetting } from 'store/settings';

export default defineComponent({
    name: "Layout",
    computed: {},
    components: {
        headerElement,
        navigationElement,
        footerElement
    },
    setup() {

        // Our defaults
        const route = useRoute();

        // Define our parameters
        const componentData = reactive({
            name: route.name
        });

        return {
            componentData
        };
    },
});
</script>
