<template>
    <div class="maincontainer" ref="maincontainerRef" :key="module">
        <div class="maincolumn">
            <div class="ibizz-widget" />
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, inject } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "dashboardView",
    setup() {
        const maincontainerRef = ref();
        const module = inject("module");
        const route = useRoute();
        const router = useRouter();

        const widget_url = inject("widget_url");

        const loadWidget = async (type = "admin") => {
            const URL = widget_url(module.value);
            if (URL === null) {
                //Config not loaded yet, await it
                let widgetWatcher;
                widgetWatcher = watch(
                    () => widget_url(module.value),
                    (newURL, oldURL) => {
                        if (!newURL) return;
                        if (newURL !== oldURL) {
                            loadWidget(type);
                            widgetWatcher && widgetWatcher();
                        }
                    },
                    { immediate: true }
                );
                return;
            }
            try {
                const URL = widget_url(module.value);
                const response = await fetch(`${URL}/entrypoints-${type}.json`);
                const data = await response.json();
                const scripts = data.chunks;

                scripts.forEach(script => {
                    if (!document.querySelector(`script[src="${URL}/${script}"]`)) {
                        const scriptElement = document.createElement('script');
                        scriptElement.src = `${URL}/${script}`;
                        scriptElement.async = false; // Ensure scripts are loaded in order
                        scriptElement.onload = () => {
                            // console.log(`${script} loaded successfully`);
                        };
                        scriptElement.onerror = () => console.error(`Failed to load ${script}`);
                        maincontainerRef.value.appendChild(scriptElement);
                    }
                });
            } catch (error) {
                console.error(`Failed to load ${type} entrypoints:`, error);
            }
        };

        onMounted(() => {
            if (module.value) {
                loadWidget("admin");
            }
        });

        watch(
            () => module.value,
            (newModule, oldModule) => {
                if (newModule !== oldModule) {
                    loadWidget("admin");
                }
            },
            { immediate: true }
        );

        return {
            route,
            router,
            module,
            maincontainerRef
        };
    }
});
</script>
