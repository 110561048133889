<template>
    <span class="icon" :name=this.name v-html="componentData.icon"></span>
</template>

<style>
span.icon {
    display:inline-block; width:1em; height:1em;
}
span.icon svg { width:1em; height:1em; display:block; fill:currentColor; color:inherit; }
</style>

<script>
import { defineComponent, onMounted, inject, watch, reactive } from "vue";

export default defineComponent({
    name: "Icon",
    props: {
        name: {
            type: String,
            required: true
        }
    },
    setup(props) {

        const componentData = reactive({
            icon: {}
        });

        const appData = inject("appData");
        watch(
            () => props.name,
            (name) => {
                if (!name) return;
                if (!(name in appData.icons)) {
                    appData.icons[name] = "";
                    fetch(`/icon/${props.name}.svg`)
                        .then(response => {
                            if (!response.ok) {
                                console.log(`Error with icon '${props.name}': ${response.statusText}`);
                                return "";
                            }
                            return response.text();
                        })
                        .then(data => {
                            componentData.icon = appData.icons[name] = data;
                        })
                        .catch(console.error.bind(console));
                }
                componentData.icon = appData.icons[name];
            },
            {
                immediate: true
            }
        );
        watch(
            () => appData.icons[props.name],
            () => {
                componentData.icon =  appData.icons[props.name];
            }
        );

        return {
            componentData
        }
    },
});
</script>