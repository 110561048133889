<template>
    <div ref="fieldRef" class="cs_formgrid">
        <template v-for="field in componentData.fields">
            <div :class="getClassesByField(field)">
                <component
                    :is="resolveType(field.type)"
                    v-bind="field"
                    v-if="visible(field)"
                    :value="value(field)"
                    :module_object="this.module_object"
                    :editing="this.editing"
                    :type="resolveType(field.type)"
                    :hidden="addressLookup.result && !addressError && ['place', 'street'].includes(field.name) && !addressLookup.editing"
                    @blur="handleBlur"
                    @change="handleBlur"
                    @valueChange="handleValueChange"
                    @valueHide="handleValueHide"
                >
                    <div class="element TextField">
                        <label>{{ field.label }}</label>
                        <small>{{ field.type }} isn't available yet</small>
                    </div>
                </component>
            </div>
            <div class="span12 row1 addressValidation" v-if="field.name === 'place' && addressLookup.invalid">
                <Icon name="info" />
                <span class="title">
                    {{ trans("De combinatie van postcode en straatnaam/woonplaats is onbekend. Weet u zeker dat dit klopt?") }}
                </span>
            </div>
            <div class="span12 row1 addressLookupResults" v-if="field.name === 'place' && addressLookup.active && addressLookup.result && !addressLookup.editing">
                {{ addressLine }}
                <u v-if="!addressLookup.editing && !addressError" @click.prevent="addressLookup.editing = true;">
                    {{ trans("Gegevens bewerken") }}
                </u>
            </div>
        </template>
    </div>
</template>

<script>
import { defineComponent, watch, reactive, provide, ref, computed } from "vue";
import { apiHostname } from "config";

import Icon from "components/icon.vue";

import { getUrlWithQueryDataAdded } from "helpers/_structure";

import trans from "helpers/_translation";

// Our components
import Elements from 'formbuilder/fields/_index';

export default defineComponent({
    name: "fields",
    props: {
        form: {
            type: Object,
            required: false
        },
        fields: {
            type: Array,
            required: true
        },
        values: {
            type: Object,
            required: true
        },
        module_object: {
            type: String,
            required: false,
            default: ""
        },
        show_label: {
            type: Boolean,
            required: false,
            default: true
        },
        show_placeholder: {
            type: Boolean,
            required: false,
            default: false
        },
        show_placeholder_on_input: { // The placeholder will be showed smaller at the side of the input
            type: Boolean,
            required: false,
            default: true
        },
        show_error_message: {
            type: Boolean,
            required: false,
            default: true
        },
        show_state: {
            type: Boolean,
            required: false,
            default: true
        },
        show_tooltip_inline: {
            type: Boolean,
            required: false,
            default: false
        },
        // This boolean allows us too set all fields to readonly at start and only allow editing when requested
        editing: {
            type: Boolean,
            required: false,
            default: true
        },
        // Boolean which adds translation when needed, by default false since we get data from API
        translate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ...Elements,
        Icon
    },
    setup(props, { emit }) {
        const fieldRef = ref();
        const componentData = reactive({
            fields: [],
            values: {}
        });

        watch(
            () => props.values,
            (values) => {
                if (!values) return;
                componentData.values = values;
            },
            {
                immediate: true
            }
        );

        if (props.form) {
            provide("formObject", props.form.formObject);
        }

        const getFieldData = (fields = []) => {
            const result = [];
            // Duplicate the array with objects, so it hass no reference
            JSON.parse(JSON.stringify(fields)).forEach(field => {
                const bools = [
                    "auto_fill",
                    "multiple_languages",
                    "readonly",
                    "validate",
                    "float"
                ];
                // Reformat our booleans, making sure we get true or false
                bools.forEach(key => {
                    field[key] = (typeof field[key] === "boolean") ? field[key] : (field[key] === "Y");
                });
                if (!field?.options && field.data?.optionsJson) {
                    field.options = JSON.parse(field.data.optionsJson);
                }
                if (!field?.options && field?.options_json) {
                    field.options = JSON.parse(field.options_json);
                }
                if (!("id" in field)) {
                    field.id = field.name.toLowerCase().replace(/\s/g, '_');
                }
                if (!("placeholder" in field) || field.placeholder === "") {
                    field.placeholder = field.label;
                }
                // Pass data
                [
                    "show_label",
                    "show_placeholder",
                    "show_placeholder_on_input",
                    "show_error_message",
                    "show_state",
                    "show_tooltip_inline",
                    "translate"
                ].forEach(key => {
                    if (key in field) return;
                    field[key] = props[key];
                });
                result.push(field);
            });
            return result;
        }
        const formatFieldData = (fields = []) => {
            componentData.fields = getFieldData(fields);
        };

        const resolveType = (type = "") => {
            return type.split("\\").pop();
        };

        const addressLookup = reactive({
            active: false,
            editing: true,
            targets: ["country", "zipcode", "housenumber"],
            validate_targets: ["street", "place"],
            values: {},
            result: null
        });
        const checkForAddressFields = () => {
            // Define if we have a address field, this is based on the inputs passed, can be seperated
            let hasAddressFields = true;
            [...addressLookup.targets, "street", "place"].forEach(name => {
                if (componentData.fields.some(field => field.name === name)) return;
                hasAddressFields = false;
            });
            addressLookup.active = hasAddressFields;
        }
        watch(
            () => props.fields,
            () => {
                formatFieldData(props.fields);
                checkForAddressFields();
            },
            {
                immediate: true,
                deep: true
            }
        );

        const getClassesByField = (field) => {
            // Define our defaults
            const result = {
                colclass : "span12",
                rowclass : "row1"
            }
            if (resolveType(field.type) === "EditorField") {
                result.colclass = "span12";
            }

            Object.keys(result).forEach(key => {
                if (!field.data?.[key]) return;
                result[key] = field.data?.[key];
            });

            return [ ...Object.values(result) ];
        };

        const handleAddressValidation = (target) => {
            if (addressLookup.active && addressLookup.validate_targets.includes(target.name)) {
                addressLookup.values = {};
                addressLookup.invalid = false;
                addressLookup.targets.forEach(name => {
                    addressLookup.values[name] = fieldRef.value.querySelector(`[name="${name}"]`)?.value ?? "";
                })

                if (addressLookup.values.zipcode && addressLookup.values.housenumber) {
                    fetch(getUrlWithQueryDataAdded(addressLookup.values, `${apiHostname}/api/v1.0/lookup/zipcode`))
                        .then((response) => {
                            return response.json();
                        })
                        .then((json) => {
                            if (!json) return;
                            addressLookup.result = json;
                            if (json && (json.data?.street.toLowerCase() !== componentData.values.street.toLowerCase() || json.data?.place.toLowerCase() !== componentData.values.place.toLowerCase())) {
                                addressLookup.invalid = true;
                            }
                        });
                }
            }
        }
        const handleAddressLookup = (target) => {
            if (addressLookup.active && addressLookup.targets.includes(target.name)) {
                addressLookup.values = {};
                addressLookup.error = false;
                addressLookup.targets.forEach(name => {
                    addressLookup.values[name] = fieldRef.value.querySelector(`[name="${name}"]`)?.value ?? "";
                })

                if (addressLookup.values.zipcode && addressLookup.values.housenumber) {
                    fetch(getUrlWithQueryDataAdded(addressLookup.values, `${apiHostname}/api/v1.0/lookup/zipcode`))
                        .then((response) => {
                            if (response.status == 401) {
                                // 401, no api token
                                addressLookup.result = null;
                                addressLookup.active = false;
                                return;
                            } else if (response.status > 401) {
                                addressLookup.result = null;
                                addressLookup.error = true;
                                return;
                            }
                            return response.json();
                        })
                        .then((json) => {
                            if (!json) return;
                            addressLookup.result = json;
                            ["street", "place"].forEach(key => {
                                componentData.values[key] = json.data?.[key];
                            });
                        });
                }
            }
        }
        const handleBlur = (e) => {
            handleAddressValidation(e.target);
            handleAddressLookup(e.target);
        }

        const addressError = computed(() => {
            if (addressLookup.error || addressLookup.result.errors?.length) {
                return true;
            }
            return false;
        });
        const addressLine = computed(() => {
            if (addressError.value) {
                return "";
            }
            const a = addressLookup.result.data;
            if (!a) return "";
            return `${a.street} ${a.housenumber}, ${a.zipcode} ${a.place}, ${a.state}`;
        });

        const handleValueChange = (params) => {
            componentData.values[params.name] = params.value;
            emit("value-change", params);
        }
        const handleValueHide = (params) => {
            emit("value-hide", params);
        }

        const value = (field) => {
            return computed(() => {
                return componentData.values[field.name] ?? field.default_value;
            }).value;
        }
        const visible = (field) => {
            return computed(() => {
                let visible = true;
                if (field.data?.conditions?.length) {
                    field.data.conditions.forEach(condition => {
                        // For "AND" operator, every condition should be met.
                        if (condition.logical_operator === "AND") {
                            condition.logical_values.every(logical_field => {
                                const tmpField = componentData.fields.find(a => a.name === logical_field.name);
                                visible = logical_field.value.some(a => `${a}` === `${value(tmpField)}`);
                            });
                        }

                        // For "OR" operator, at least one condition should be met.
                        if (condition.logical_operator === "OR") {
                            return Object.keys(condition.logical_values).some(logical_field => {
                                const tmpField = componentData.fields.find(a => a.name === logical_field.name);
                                visible = logical_field.value.some(a => `${a}` === `${value(tmpField)}`);
                            });
                        }

                    });
                }
                return visible;
            }).value;
        }

        return {
            trans,
            fieldRef,
            addressLookup,
            addressLine,
            addressError,
            componentData,
            resolveType,
            getClassesByField,
            handleBlur,
            handleValueChange,
            handleValueHide,

            value,
            visible
        };
    },
});
</script>

<style></style>