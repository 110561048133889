<template>
    <label v-if="this.show_label" :for="`field_${this.id}`">
        <span class="title">
            <span class="text">{{ labelText }}</span>
            <small v-if="this.note">{{ this.note }}</small>
            <span v-if="this.required" class="asterisk">*</span>
        </span>
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="false" />
    </label>
</template>

<script>
import { defineComponent, computed } from "vue";
// Our form helpers
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

import tooltipElement from "formbuilder/elements/TooltipElement.vue";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

export default defineComponent({
    name: "label-element",
    components: {
        tooltipElement
    },
    props: mergeDeep(
        propDefaults,
        {
            error: {
                type: Object,
                required: false
            }
        }
    ),
    setup(props) {
        const labelText = computed(() => {
            return props?.translate ? trans(props.label) : props.label;
        })
        return {
            trans,
            resolveProps,
            labelText
        };
    }
});
</script>