<template>
    <nav class="mainnav">
        <div class="logocontainer" v-if="isMobile">
            <Logo />
        </div>
        <div class="top">
            <div class="navGroup">
                <ul class="nav navbar-nav">
                    <li v-for="module in config_value('site.widgets.enable', site_id)">
                        <button :class="{ active: active(module) }" type="button" @click.prevent="handleClick('/widget', { module: module, site_id: site_id })">
                            <icon :name="module_icon(module)" />
                            <span class="title">{{ module_title(module) }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom">
        <button type="button" @click.prevent="toggleMode">
            <Icon name="darkmode" />
            <span class="title">{{ textMode }}</span>
        </button>
        <button type="button" @click="handleLogout">
            <Icon name="logout" />
            <span class="title">{{ trans("Uitloggen") }}</span>
        </button>
        </div>
    </nav>
</template>

<script>
import {defineComponent, provide, ref, computed, inject} from "vue";
import { useRouter, useRoute } from "vue-router";

// Components
import Icon from "components/icon";

// Elements
import Logo from "elements/logo";

// Helpers
import trans from "helpers/_translation";
import { isMobile } from "helpers/_device";
import { getQuery } from "helpers/_structure";

import { env } from "config";
import { getSetting, storeSetting, matchSetting } from 'store/settings';

// Services
import { destroyTokens } from "services/JwtService";

export default defineComponent({
    name: "navigationElement",
    components: {
        Logo,
        Icon
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const site_id = inject("site_id");
        const currentPayload = inject("currentPayload");

        const config_value = inject("config_value");

        const module_config = inject("module_config");
        const module_title = inject("module_title");
        const module_icon = inject("module_icon");

        const handleClick = inject("handleClick");

        const navigationgroupList = computed(() => {
            return env("navigationgroup");
        });

        const handleLogout = () => {
            destroyTokens();
            router.push("/login");
            setTimeout(() => {
                location.reload();
            }, 100);
        };

        const toggleMode = () => {
            const newValue = matchSetting("layout", "mode", "light") ? "dark" : "light";
            storeSetting("layout", "mode", newValue);
        };
        provide("toggleMode", toggleMode);

        const textMode = computed(() => {
            return matchSetting("layout", "mode", "light") ? trans('Donkere modus') : trans('Lichte modus');
        });

        // Button related code
        const active = (module) => {
            return computed(() => {
                return currentPayload.value?.module === module;
            }).value;
        };

        return {
            trans,

            env,

            isMobile,

            navigationgroupList,

            handleLogout,
            handleClick,

            active,

            toggleMode,
            textMode,

            config_value,

            module_config,
            module_title,
            module_icon,

            site_id
        };
    },
});
</script>