<template>
    <div class="element" :data-required="required" :class="{ TagField: true, [this.type] : true, error : error.error, success: !error.error && error.init, hidden: this.hidden }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />

        <input type="hidden" ref="inputRef" :name="this.name" :value="inputValue" @change="handleChange">
        <div class="dropdown" :data-name="this.name">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    name=""
                    :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
                    :value="searchValue"
                    ref="searchRef"
                    @click="handleSearchClick"
                    @focus="handleSearchFocus"
                    @blur="handleSearchBlur"
                    @input="handleSearchInput"
                >
                <div class="input-group-append" v-if="inputValue && !searchFocus">
                    <button class="btn" type="button" @click.prevent="handleSearchReset">
                        <Icon name="close" />
                    </button>
                </div>
                <div class="input-group-append" v-if="show_dropdown">
                    <button class="btn" type="button" @click.prevent="handleDropdownHide">
                        <Icon name="up" />
                    </button>
                </div>
            </div>
            <div class="dropdown-menu show" v-if="show_dropdown">
                <ul class="block-grid one-up">
                    <li v-for="option in componentData.options">
                        <div class="dropdown-item" :class="{active: isOptionActive(option)}" @click.prevent="handleSearchSelection(option)">
                            <span class="title">{{ option.title }}</span>
                            <Icon name="close"  v-if="isOptionActive(option)"/>
                            <Icon name="hidden" v-if="!isOptionActive(option) && this.hideable" @click.stop="handleSearchHide(option)"/>
                        </div>
                    </li>
                    <li v-if="!componentData.options.length">
                        {{ trans("Geen opties gevonden.") }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="tags" :class="{dragging: isDragging}">
            <div class="tag" draggable="true" @dragstart="dragStart" @dragover.stop="allowDrop" @drop="drop" v-for="(id, key) in inputArray" :data-key="key">
                <span class="title">{{ current_option(id) }} <template v-if="key === 0 && this.primary_label">({{ trans(this.primary_label) }})</template></span>
                <button type="button" class="nobg" @click.stop="handleOption({id: id})">
                    <Icon name="close" />
                </button>
            </div>
        </div>

        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {defineComponent ,ref, watch, reactive, computed, inject, onMounted, onUnmounted} from "vue";

// Our form helpers
import { postDataAsync, patchDataAsync, getDataAsync } from "helpers/_api";
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";
import { observeElement } from "helpers/_observer";

import Icon from "components/icon";

import { store_entity } from "store";

export default defineComponent({
    props: mergeDeep(
        propDefaults,
        {
            primary_label: {
                type: String,
                required: false,
                default: "primair"
            },
            hideable: {
                type: Boolean,
                required: false,
                default: false
            },
            options_object: {
                type: String,
                required: false,
                default: ""
            }
        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement,

        Icon
    },
    setup(props, { emit }) {
        const inputRef = ref();
        const searchRef = ref();
        const inputArray = ref([]);
        const inputValue = computed(() => {
            return inputArray.value.join(",");
        });
        const {registerValidator, validate, error} = useFieldValidation();

        const componentData = reactive({
            options: {},
            hidden: {}
        });

        const resolveOptions = (options) => {
            if (!props.required) {
                options = {
                    "": `- Selecteer een ${props.label} -`,
                    ...options
                };
            }
            return options;
        };
        watch(
            () => props.options,
            (options) => {
                componentData.options = resolveOptions(options);
            },
            {
                immediate: true,
                deep: true
            }
        );

        watch(
            () => props.value,
            () => {
                const tmpValue = props.value || "";
                inputArray.value = tmpValue.split(",").filter(a => a !== "");
            },
            {
                immediate: true
            }
        );

        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(inputValue, props);
            },
            {
                immediate: true
            }
        );

        // If we got a initial value, validate instantly
        props.value && validate();

        const handleChange = () => {
            emit('value-change', {
                name: props.name,
                value: inputValue.value
            });
        }


        const searchValue = ref("");
        const current_match = computed(() => {
            const result = Object.entries(componentData.options).find(([key, value]) => {
                return value.toLowerCase() === searchValue.value?.toLowerCase();
            });
            return  result ? {
                id: result[0],
                title: result[1]
            } : null;
        })
        const current_option = (value) => {
            return computed(() => {
                if (!(value in componentData.options)) {
                    if (!(value in componentData.hidden)) {
                        componentData.hidden[value] = {};
                        getDataAsync(`/api/v1.0/${searchModule.value.toLowerCase()}/${value}`).then((result) => {
                            componentData.hidden[value] = result.data.title;
                        });
                    }
                    return componentData.hidden[value];
                }
                return componentData.options[value];
            }).value;
        };

        const searchModule = computed(() => {
            return props.options_object.split("::")[0].replace("Mapper", "");
        });
        const searchSelection = ref(false);
        const searchAdding = ref(false);
        const searchFocus = ref(false);
        const show_dropdown = computed(() => {
            return searchFocus.value || searchAdding.value || searchSelection.value;
        });
        const show_add = computed(() => {
            if (!props.options_object?.includes("Mapper::")) return false;
            return !current_match.value;
        });

        const isOptionActive = (option) => {
            return computed(() => {
                return inputArray.value.includes(`${option.id}`);
            }).value;
        }
        const handleOption = (option) => {
            if (isOptionActive(option)) {
                inputArray.value = inputArray.value.filter(a => {
                    return `${a}` != `${option.id}`;
                });
            } else {
                inputArray.value.push(`${option.id}`);
            }
            handleChange();
        }

        let searchTimeout;
        const handleSearch = () => {
            getDataAsync(`/api/v1.0/${searchModule.value.toLowerCase()}`, [
                [
                    "SearchFilter",
                    {
                        "c": ["title"],
                        "v": searchValue.value
                    }
                ]
            ]).then((result) => {
                componentData.options = Object.values(result.data.map(item => {
                    return {
                        id: item.data.id,
                        title: item.data.title
                    };
                }));
            });
        }
        const handleSearchInput = (e) => {
            e.target.value = `${e.target.value.charAt(0).toUpperCase()}${e.target.value.slice(1)}`;
            searchValue.value = e.target.value;

            // Set timeout and clear timeout on input
            // So the search call is delayed until certain time without typing is passed
            searchTimeout && clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
                // Do the search
                handleSearch();
            }, 400);
        }
        const handleSearchReset = () => {
            searchValue.value = "";
        }
        const handleSearchClick = (e) => {
            e.target.setSelectionRange(0, e.target.value.length)
        }
        const handleSearchFocus = () => {
            searchFocus.value = true;
        }
        const handleSearchBlurSuspended = ref(false);
        const handleSearchBlur = () => {
            setTimeout(() => {
                if (handleSearchBlurSuspended.value) return;
                searchFocus.value = false;
                // if (current_match.value && !isOptionActive(option)) {
                //     handleOption(current_match.value);
                // }
                searchValue.value = "";
            }, 200);
        }
        const handleDropdownHide = () => {
            searchFocus.value = searchSelection.value = false;
        }


        const handleSearchSelection = (option) => {
            // searchSelection.value = true; // Disabled, no need to keep open atm
            handleOption(option);
        }
        const handleSearchHide = (option) => {
            handleSearchBlurSuspended.value = true;
            searchRef.value.focus();
            emit('value-hide', {
                name: props.name,
                value: option.id
            });
            setTimeout(() => {
                handleSearchBlurSuspended.value = false;
            }, 200);
        }


        const killswitch = inject("killswitch");
        const handleDropdownOutside = (e) => {
            if (e.target.closest(`.dropdown[data-name='${props.name}']`)) return;
            searchFocus.value = searchSelection.value = false;
        }
        onMounted(() => {
            if (props.readonly) {
                observeElement(inputRef.value, "value", (oldValue, newValue) => {
                    if (props.value == newValue) return;
                    console.error("DOM manipulation not allowed", oldValue, newValue);
                    killswitch && killswitch();
                });
            }

            document.addEventListener("click", handleDropdownOutside);
        });
        onUnmounted(() => {
            document.removeEventListener("click", handleDropdownOutside);
        });


        let oldIndex = null;
        const isDragging = ref(false);
        const dragStart = (e) => {
            isDragging.value = true;
            oldIndex = e.target.dataset.key;
        }

        const allowDrop = (e) => {
            e.preventDefault();
        }

        const drop = (e) => {
            e.preventDefault();

            const newIndex = e.target.dataset.key;

            const tmpArray = inputArray.value;
            tmpArray.splice(newIndex, 0, tmpArray.splice(oldIndex, 1)[0]);
            inputArray.value = tmpArray;

            isDragging.value = false;
        }

        return {
            trans,
            inputValue,
            inputArray,
            inputRef,
            searchRef,
            error,

            componentData,
            resolveProps,
            validate,
            required,

            handleChange,

            searchValue,
            searchFocus,
            searchSelection,
            show_dropdown,
            show_add,

            isOptionActive,
            current_option,
            current_match,

            handleSearchInput,
            handleSearchClick,
            handleSearchFocus,
            handleSearchBlur,
            handleSearchReset,
            handleSearchSelection,
            handleSearchHide,
            handleDropdownHide,

            handleOption,

            dragStart,
allowDrop,
            drop
        };
    },
});
</script>